import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Container from "react-bootstrap/Container"
import Layout from "../components/Layout"
import Hero from "../components/Home/Hero/Hero"
import ToursList from "../components/Tours/ToursList/ToursList"
import TimeLap from "../components/Home/TimeLap/TimeLap"
import Overlay from "../components/UI/Overlay"
import Features from "../components/Home/Features/Features"
import Testimonials from "../components/Home/Testimonials/Testimonials"
import styles from "../css/index.module.css"

const Index = ({ data }) => {
  const [isYTOpen, setYTOpen] = useState(false)
  return (
    <Layout title="The Best Jaisalmer Desert Safari Tours" description="">
      <Hero
        videos={{
          homeVideomp4: data.homeVideomp4,
          homeVideowebm: data.homeVideowebm,
          homeVideoogv: data.homeVideoogv,
        }}
        poster={data.homePoster}
      />
      <section
        style={{ backgroundColor: "#f9f9f9" }}
        className={styles.section}
      >
        <Container>
          <h2 className="text-center text-uppercase font-weight-bold">
            Jaisalmer <span className="text-primary">Desert Safari</span> Top
            Tours
          </h2>
          <ToursList />
        </Container>
      </section>
      <BackgroundImage
        Tag={`section`}
        fluid={data.backImage.childImageSharp.fluid}
        backgroundColor={`#040e18`}
        style={{
          backgroundAttachment: "fixed",
          zIndex: isYTOpen ? "1039" : "1",
        }}
      >
        <Overlay />
        <TimeLap isYTOpen={isYTOpen} setYTOpen={setYTOpen} />
      </BackgroundImage>
      <section
        style={{ backgroundColor: "#ffffff" }}
        className={styles.section}
        id="testimonial"
      >
        <Container>
          <h2 className="text-center text-uppercase">
            What <span className="text-primary">Customer</span> Says
          </h2>
          <p className="text-center">
            Satisfied customer is the best source of advertisement
          </p>
          <Testimonials />
        </Container>
      </section>
      <section
        style={{ backgroundColor: "#f9f9f9" }}
        className={styles.section}
      >
        <Container>
          <h2 className="text-center text-uppercase">
            Some <span className="text-primary">Good</span> Reasons
          </h2>
          <p className="text-center">
            Satisfied customer is the best source of advertisement
          </p>
          <Features />
        </Container>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    backImage: file(relativePath: { eq: "backgrounds/desertBack.jpg" }) {
      id
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    homePoster: file(relativePath: { eq: "home_poster.jpg" }) {
      publicURL
    }
    homeVideomp4: file(relativePath: { eq: "home_video.mp4" }) {
      publicURL
    }
    homeVideowebm: file(relativePath: { eq: "home_video.webm" }) {
      publicURL
    }
    homeVideoogv: file(relativePath: { eq: "home_video.ogv" }) {
      publicURL
    }
  }
`
export default Index
