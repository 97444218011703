import React from 'react';
import Image from 'gatsby-image';
import {IoIosStar,IoIosStarOutline} from 'react-icons/io';
import styles from './testimonials.module.css';
const Testimonial = ({testimonial}) => { 
    const {title, acf:{testimonial:testi, rating}, featured_media:{localFile:{childImageSharp:{fluid:avatar}}}} = testimonial;

    return (
        <div className={styles.Testimonial}>
            <div className={styles.TitleAvatar}>
                <div className={styles.Avatar}>
                    <Image fluid={avatar}/>
                </div>
                <div className={styles.Title}>
                    {title}
                </div>
            </div>
            <div className={styles.TestiText}>
                "{testi}"
            </div>
            <div className={styles.Rating}>
                { Array.from({length: 5}).map((_,i) => i+1<=parseInt(rating) ? <IoIosStar className={styles.FilledStar} key={`${title}_${i}`}/> : <IoIosStarOutline key={`${title}_${i}`}/>) }
            </div>
        </div>
    )
}

export default Testimonial;
