import React from "react"
import styles from "./Hero.module.css"
import { Link } from "gatsby"

const Hero = ({ videos, poster }) => {
  return (
    <div className={styles.HeroContainer}>
      <video autoPlay loop muted poster={poster.publicURL} className={styles.Hero}>
        <source src={videos.homeVideomp4.publicURL} type="video/mp4" />
        <source src={videos.homeVideowebm.publicURL} type="video/webm" />
        <source src={videos.homeVideoogv.publicURL} type="video/ogv" />
      </video>
      <div className={styles.HeroContain}>
        <h1 className="text-uppercase">Welcome to golden city Jaisalmer</h1>
        <h4>Desert Safari - Jaisalmer Tours</h4>
        <div className="mt-3">
          <Link
            to="/tours"
            className={`btn btn-primary mr-2 ${styles.HeroLinks}`}
          >
            View Packages
          </Link>
          <Link
            to="/tour-guide"
            className={`btn btn-outline-light ${styles.HeroLinks}`}
          >
            Tour Guide
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hero
