import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import Testimonial from './Testimonial'; 

const getTestimonials = graphql `
{
  allTestimonials:allWordpressWpTestimonials{
    edges{
      node{
        id 
        title
        acf{
          testimonial
          rating
        } 
        featured_media{
           localFile{
            childImageSharp{
              fluid(maxWidth:76){ 
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
}

`;

const Testimonials = () => {
    const { allTestimonials: {edges:testimonials} } = useStaticQuery(getTestimonials); 
    return (
        <Container className="mb-5">
            <Carousel 
              controls={false} 
              fade={true} 
              interval={2000} 
              indicators={false}
            >
                 {
                     testimonials.map(({node}) => <Carousel.Item key={node.id} className="pt-5"><Testimonial testimonial={node}/></Carousel.Item>)
                 }
            </Carousel>
        </Container>
    )
};

export default Testimonials;
