import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image'; 
import Button from 'react-bootstrap/Button';
import styles from './TourCard.module.css';
import featureIcons from '../../../contants/FeatureIconMap';

const TourCard = ({tour}) => {
    const {wordpress_id, title, slug, featured_media: { localFile: { childImageSharp: { fluid: mainImage } } }, acf: { package_duration, package_cost, package_features }} = tour;
 
    return (
        <Link className={styles.TourCard} to={`/tours/${slug}`}>
            <div className={styles.imgContainer}>
                <Img fluid={mainImage} alt={title} objectFix="cover" objectPosition="50% 50%" className={styles.Img}/>
                <div className={styles.imgInfo}>
                    <div className={styles.featureIcons}>
                        {
                            package_features.map(feature => (<span key={feature.value} className={styles.featureIcon}>{featureIcons[feature.value]}</span>))
                        }
                    </div>
                    <p className={styles.price}><sup>₹</sup>{package_cost}</p>
                </div>
            </div>
            <div className={styles.Info}>
                <div className={styles.InfoTitleDuration}>
                    <div className={styles.infoTitle}>
                       <p dangerouslySetInnerHTML={{__html: title}}></p>
                    </div>
                    <div className={styles.duration}>
                        { `${package_duration>1 ? package_duration+' days' : package_duration+' day'}  ${package_duration -1 ? package_duration -1 + ' Night ' : ''} ` || '--'}
                    </div>
                </div>
                <div className={styles.bookNow} data-id={wordpress_id}>
                    <Button title={title} className="btn btn-primary btn-sm font-weight-bold">
                        Book Now
                    </Button> 
                </div>
            </div>
        </Link>
    );
};

export default TourCard;
