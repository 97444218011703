import React  from 'react'; 
import ModalVideo from 'react-modal-video'; 
import styles from './TimeLap.module.css'; 
import 'react-modal-video/scss/modal-video.scss';
const TimeLap = ({isYTOpen, setYTOpen}) => { 

    
    return (
        <div className={styles.TimeLap}>
            <h2 className="text-center text-uppercase">Jaisalmer TImelap</h2>
            <p className="text-center mt-3">
                Ridiculus sociosqu cursus neque cursus curae ante scelerisque vehicula porttitor natoque risus vitae lacinia felis elit netus sed iaculis interdum nullam sem habitasse vulputate laoreet turpis fringilla duis suspendisse arcu.
            </p> 
            <div className={styles.playButton} onClick={() => setYTOpen(true)}>
                <i className='icon-play'></i> 
            </div>
            
           
            <ModalVideo channel='youtube' 
                        youtube={{autoplay:1,controls:0}} 
                        isOpen={isYTOpen} 
                        videoId='Ew5W_kmtzjM' 
                        onClose={() => setYTOpen(false)} />
        </div>
    );
}

export default TimeLap;
