import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import TourCard from '../TourCard/TourCard';
import styles from './ToursList.module.css';
const getTours = graphql `
{
	allTours:allWordpressWpTour{
    edges{
      node{
        wordpress_id
        title
        slug 
        featured_media{
           localFile{
            childImageSharp{
              fluid(maxWidth:600){ 
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        } 
        acf{
          package_duration
          package_cost
          package_place_covered 
          package_features {
            label
            value
          }
        } 
         
      } 
    }
  }
}
`;

const ToursList = () => {
    const { allTours: {edges: tours} } = useStaticQuery(getTours); 
    return (
        <div className={styles.ToursList}>
           {
               tours.map(({node}) => (<div key={node.id}  className={styles.ToursListItem}><TourCard tour={node} /></div>))
           } 
        </div>
    );
};

export default ToursList;
