import React from 'react';
import { Link } from 'gatsby'; 
import styles from './features.module.css';
import map from '../../../images/features/map.png';
import customer from '../../../images/features/customer.png';
import support from '../../../images/features/support.png';
const data = [
    {
        title: "+120",
        subtitle: "Exotic Tours",
        description: "",
        icon: map,
        link: "/tours"
    },
    {
        title: "+1000",
        subtitle: "Customers",
        description: "",
        icon: customer,
        link: "#testimonial"
    },
    {
        title: "H24",
        subtitle: "Support",
        description: "",
        icon: support,
        link: "/contact-us"
    },
    
];

const Features = () => {
    return (
        <div className={styles.Features}>
             {data.map(feature => (
                 <div className={styles.Feature} key={feature.title}>
                    <div className={styles.IconBox}>
                        <img src={feature.icon} alt={feature.title}/>
                    </div> 
                    <div className={styles.FeatureTitle}>
                        <span>{feature.title}</span> {feature.subtitle}
                    </div>
                    <div className={styles.FeatureText}>
                        {feature.description}
                    </div>
                    <Link to={feature.link} className="btn btn-outline-primary mt-3 rounded-0 mt-3">Read More</Link>
                 </div>
             ))}
        </div>
    );
};

export default Features;
