import React from 'react'; 
const featureIcons = {
    "sightseeing": <i className="icon_set_1_icon-37"></i>,
    "art-culture": <i className="icon_set_1_icon-1"></i>,
    "transport" : <i className="icon_set_1_icon-21"></i>,
    "food": <i className="icon_set_1_icon-14"></i>,
    "jeep-safari": <i className="icon_set_1_icon-39"></i>,
    "camel-safari" : <i className="icon-picture"></i>,
    "wildlife": <i className="icon_set_1_icon-24"></i>,
}

export default featureIcons;